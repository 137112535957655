import { render, staticRenderFns } from "./what-to-do-if-a-debt-collector-calls-you.md?vue&type=template&id=173d0804&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports